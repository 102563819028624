import { h, Fragment } from "preact";
import { getPagePath } from "@nanostores/router";
import { $router } from "./router.js";

export function TermsPage() {
  return (
    <Fragment>
      <div className={"row pt-12 px-2 lg:px-0"}>
        <h1 className="text-5xl font-bold">
          <a href={getPagePath($router, "home")} className={"logo"}>
            zCred
          </a>
        </h1>
      </div>
      <div className={"row px-2 lg:px-0 font-light"}>
        Zero Disclosure Verifiable Credentials
      </div>
      <div className={"row mt-2 px-2 lg:px-0"}>
        <h2>Terms & Privacy Policy</h2>
        <p>
          zCred is designed to never collect or store any sensitive information.
          Credentials you store and manage with us cannot be read by us or other
          third parties because they are always end-to-end encrypted, private,
          and secure. Our Terms of Service and Privacy Policy are available
          below.
        </p>
        <h3 id={"terms"}>Terms of Service</h3>
        <p>
          zCred project (“zCred”) utilizes state-of-the-art security,
          zero-knowledge technology and end-to-end encryption to provide private
          credentials management, and other services to users worldwide. You
          agree to our Terms of Service (“Terms”) by installing or using our
          apps, services, or website (together, “Services”).
        </p>
        <h4>About our services</h4>
        <p>
          <strong>Minimum Age.</strong> You must be at least 13 years old to use
          our Services. The minimum age to use our Services without parental
          approval may be higher in your home country.
        </p>
        <p>
          <strong>Account Registration.</strong> To create an account you must
          register for our Services using your blockchain private key.
        </p>
        <p>
          <strong>Privacy of user data.</strong> zCred does not sell, rent or
          monetize your personal data or content in any way – ever.
        </p>
        <p>
          Please read our <a href={"#privacy-policy"}>Privacy Policy</a> to
          understand how we safeguard the information you provide when using our
          Services. For the purpose of operating our Services, you agree to our
          data practices as described in our Privacy Policy, as well as the
          transfer of your encrypted information and metadata to the United
          States and other countries where we have or use facilities, service
          providers or partners. Examples would be Third Party Providers
          processing your support tickets.
        </p>
        <p>
          <strong>Software.</strong> In order to enable new features and
          enhanced functionality, you consent to downloading and/or installing
          updates to our Services.
        </p>
        <p>
          <strong>Fees and Taxes.</strong> You are responsible for data and
          mobile carrier fees and taxes associated with the devices on which you
          use our Services.
        </p>
        <h4>Using zCred</h4>
        <p>
          <strong>Our Terms and Policies.</strong> You must use our Services
          according to our Terms and posted policies. We might disable your
          account for a violation of our Terms.
        </p>
        <p>
          <strong>Legal and Acceptable Use.</strong> You agree to use our
          Services only for legal, authorized, and acceptable purposes. You will
          not use (or assist others in using) our Services in ways that: (a)
          violate or infringe the rights of zCred, our users, or others,
          including privacy, publicity, intellectual property, or other
          proprietary rights; (b) involve sending illegal or impermissible
          communications; (c) involve storing illegal or impermissible data.
        </p>
        <p>
          <strong>Harm to zCred.</strong> You must not (or assist others to)
          access, use, modify, distribute, transfer, or exploit our Services in
          unauthorized manners, or in ways that harm zCred, our Services, or
          systems. For example you must not (a) gain or try to gain unauthorized
          access to our Services or systems; (b) disrupt the integrity or
          performance of our Services; (c) collect information about our users
          in any unauthorized manner; or (e) sell, rent, or charge for our
          Services.
        </p>
        <p>
          <strong>Keeping Your Account Secure.</strong> zCred embraces privacy
          by design and does not have the ability to access your credentials and
          related data. You are responsible for keeping your device, your zCred
          account, private keys used safe and secure.
        </p>
        <p>
          <strong>Third-party services.</strong> Our Services may allow you to
          access, use, or interact with third-party websites, apps, content, and
          other products and services. When you use third-party services, their
          terms and privacy policies govern your use of those services.
        </p>
        <p>
          <strong>Proving credentials to third-parties.</strong> Our services
          may allow you to disclose or prove credentials data you store. You are
          responsible for not disclosing data you consider fully private.
        </p>
        <h4>Your Rights and License with zCred</h4>
        <p>
          <strong>Your Rights.</strong> You own the information you submit
          through our Services.
        </p>
        <p>
          <strong>Rights of zCred.</strong> We own all copyrights, trademarks,
          domains, logos, trade dress, trade secrets, patents, and other
          intellectual property rights associated with our Services. You may not
          use our copyrights, trademarks, domains, logos, trade dress, patents,
          and other intellectual property rights unless you have our written
          permission. To report copyright, trademark, or other intellectual
          property infringement, please contact{" "}
          <a href={"mailto:dd9efe7fb5c01@protonmail.com"}>
            dd9efe7fb5c01@protonmail.com.
          </a>
        </p>
        <p>
          <strong>zCred’s License to You.</strong> zCred grants you a limited,
          revocable, non-exclusive, and non-transferable license to use our
          Services in accordance with these Terms.
        </p>
        <h4>Disclaimers and Limitations</h4>
        <p>
          <strong>Disclaimers.</strong> YOU USE OUR SERVICES AT YOUR OWN RISK
          AND SUBJECT TO THE FOLLOWING DISCLAIMERS. WE PROVIDE OUR SERVICES ON
          AN “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING,
          BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER
          VIRUS OR OTHER HARMFUL CODE. ZCRED DOES NOT WARRANT THAT ANY
          INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR
          SERVICES WILL BE OPERATIONAL, ERROR-FREE, SECURE, OR SAFE, OR THAT OUR
          SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS.
          WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR
          WHEN OUR USERS USE OUR SERVICES. WE ARE NOT RESPONSIBLE FOR THE
          ACTIONS OR INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD
          PARTIES. YOU RELEASE US, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
          PARTNERS, AND AGENTS (TOGETHER, “ZCRED PARTIES”) FROM ANY CLAIM,
          COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR DISPUTE (TOGETHER,
          “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO, ARISING OUT OF,
          OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD
          PARTIES.
        </p>
        <p>
          <strong>Limitation of liability.</strong> THE ZCRED PARTIES WILL NOT
          BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL,
          PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF,
          OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES, EVEN
          IF THE ZCRED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR IN
          ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES WILL NOT
          EXCEED TEN THOUSAND RUSSIAN RUBLES (10000₽). THE FOREGOING DISCLAIMER
          OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES OR
          JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
          DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS SET FORTH
          ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY
          IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF THE ZCRED PARTIES WILL
          BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
        <p>
          <strong>Availability of Our Services.</strong> Our Services may be
          interrupted, including for maintenance, upgrades, or network or
          equipment failures. We may discontinue some or all of our Services,
          including certain features and the support for certain devices and
          platforms, at any time.
        </p>
        <h4>Resolving Disputes and Ending Terms</h4>
        <p>
          <strong>Resolving disputes.</strong> You agree to resolve any Claim
          you have with us relating to or arising out of our Terms, us, or our
          Services exclusively in Volgograd Regional Court, Russia. You also
          agree to submit to the personal jurisdiction of such courts for the
          purpose of litigating all such disputes. The laws of Volgograd, Russia
          govern our Terms, as well as any disputes, whether in court or
          arbitration, which might arise between zCred and you, without regard
          to conflict of law provisions.
        </p>
        <p>
          <strong>Ending these Terms.</strong> You may end these Terms with
          zCred at any time by discontinuing use of our Services. We may modify,
          suspend, or terminate your access to or use of our Services anytime
          for any reason, such as if you violate the letter or spirit of our
          Terms or create harm, risk, or possible legal exposure for zCred. The
          following provisions will survive termination of your relationship
          with zCred: “Licenses,” “Disclaimers,” “Limitation of Liability,”
          “Resolving dispute,” “Availability” and “Ending these Terms,” and
          “General”.
        </p>
        <h4>General</h4>
        <p>
          zCred may update the Terms from time to time. When we update our
          Terms, we will update the “Last Modified” date associated with the
          updated Terms. Your continued use of our Services confirms your
          acceptance of our updated Terms and supersedes any prior Terms. You
          will comply with all applicable export control and trade sanctions
          laws. Our Terms cover the entire agreement between you and zCred
          regarding our Services. If you do not agree with our Terms, you should
          stop using our Services.
        </p>
        <p>
          If we fail to enforce any of our Terms, that does not mean we waive
          the right to enforce them. If any provision of the Terms is deemed
          unlawful, void, or unenforceable, that provision shall be deemed
          severable from our Terms and shall not affect the enforceability of
          the remaining provisions. Our Services are not intended for
          distribution to or use in any country where such distribution or use
          would violate local law or would subject us to any regulations in
          another country. We reserve the right to limit our Services in any
          country. If you have specific questions about these Terms, please
          contact us at{" "}
          <a href={"mailto:dd9efe7fb5c01@protonmail.com"}>
            dd9efe7fb5c01@protonmail.com.
          </a>
        </p>
        <h3 id={"privacy-policy"}>Privacy Policy</h3>
        <p>
          zCred utilizes state-of-the-art security, end-to-end encryption, and
          zero-knowledge technology to provide credentialling services to users
          worldwide (“Services”). Your credentials are always encrypted, so they
          can never be shared or viewed by anyone but yourself. The proofs and
          disclosures calculated from the credentials can be viewed by the
          intended recipients in the extent you allow.
        </p>
        <h4>Information you provide</h4>
        <p>
          <strong>Account Information.</strong> You register a blockchain
          address when you create a zCred account. You may optionally add other
          information to your account, such as a profile name and profile
          picture.
        </p>
        <p>
          <strong>Credentials.</strong> zCred cannot decrypt or otherwise access
          the content of your credentials.
        </p>
        <p>
          Additional technical information is stored on our servers, including
          randomly generated authentication tokens, key hashes, and other
          material that is necessary for access control. zCred limits this
          additional technical information to the minimum required to operate
          the Services.
        </p>
        <p>
          <strong>User Support.</strong> If you contact zCred User Support, any
          personal data you may share with us is kept only for the purposes of
          researching the issue and contacting you about your case.
        </p>
        <p>
          <strong>Getting Credentials.</strong> If you receive a credential, the
          data requested by the issuer are bound by the issuer’s privacy policy.
        </p>
        <h4>Information we may share</h4>
        <p>
          <strong>Third Parties.</strong> We might work with third parties to
          provide some of our Services. These providers are bound by their
          Privacy Policies to safeguard that information. At any time we limit
          the data we might share to the minimum required.
        </p>
        <p>
          <strong>
            Other instances where zCred may need to share your data
          </strong>
        </p>
        <ul className={"list"}>
          <li>
            To meet any applicable law, regulation, legal process or enforceable
            governmental request.
          </li>
          <li>
            To enforce applicable Terms, including investigation of potential
            violations.
          </li>
          <li>
            To detect, prevent, or otherwise address fraud, security, or
            technical issues.
          </li>
          <li>
            To protect against harm to the rights, property, or safety of
            zCred, our users, or the public as required or permitted by law.
          </li>
        </ul>
        <h4>Updates</h4>
        <p>
          We will update this privacy policy as needed so that it is current,
          accurate, and as clear as possible. Your continued use of our Services
          confirms your acceptance of our updated Privacy Policy.
        </p>
        <h4>Terms</h4>
        <p>
          Please also read our <a href={"#terms"}>Terms</a> which also governs
          the terms of this Privacy Policy.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have questions about our Privacy Policy please contact us at{" "}
          <a href={"mailto:dd9efe7fb5c01@protonmail.com"}>
            dd9efe7fb5c01@protonmail.com
          </a>
          .
        </p>
        <p>The project is conceived by Pavel Deshevov and Sergey Ukustov.</p>

        <h3>Legal Entity</h3>
        <p>Legal entity responsible for the project:</p>
        <dl>
          <dt>Name</dt>
          <dd>Individual Entrepreneur Sergey Ukustov</dd>
          <dt>Identification&nbsp;Number</dt>
          <dd>344309224769</dd>
          <dt>Address</dt>
          <dd>
            51 Gvardeyskoy ul, d.8, kv.61
            <br />
            400075 Volgograd, Russia
          </dd>
        </dl>

        <p></p>
        <p>Effective as of February 12, 2024</p>
        <p>Updated February 12, 2024</p>

        <p>
          <a href={getPagePath($router, "home")}>← Back home</a>
        </p>
      </div>
    </Fragment>
  );
}
