import { h, Fragment } from "preact";
import { useState } from "preact/hooks";
import AppZcredSvg from "url:./assets/images/app-zcred.svg";
import AppHose from "url:./assets/images/app-hose.svg";
import PassportSvg from "url:./assets/images/passport.svg";
import SybilSvg from "url:./assets/images/sybil.svg";
import IdCardClip from "url:./assets/images/id-card-clip.svg";
import * as styles from "./index-page.module.css";
import { UnreachableCaseError } from "./unreachable-case-error.js";
import { getPagePath } from "@nanostores/router";
import { $router } from "./router.js";
import { TargetedEvent } from "preact/compat";

function TwoWays() {
  const [section, setSection] = useState<"new" | "old">("new");

  function renderNewSection() {
    return (
      <div className={"flex flex-row"}>
        <div className={"w-1/2 flex flex-col justify-center"}>
          <p className={"text-xs mb-8"}>
            Advances in math and technology enable individuals to assert
            essential information about themselves exposing no sensitive data. A
            user can prove facts like their age, financial stability, or
            citizenship without divulging personal information like birthdates
            or account balances.
          </p>
          <p className={"text-xs"}>
            Build applications that respect user privacy and regulatory
            standards, all while enabling robust personalization. By integrating
            with zCred, you streamline identity verification, drastically reduce
            data exposure, and ensure compliance all at once.
          </p>
        </div>
        <div className={"w-1/2 mt-1"}>
          <img src={AppZcredSvg} alt="zCred-enabled privacy" />
        </div>
      </div>
    );
  }

  function renderOldSection() {
    return (
      <div className={"flex flex-row"}>
        <div className={"w-1/2 flex flex-col justify-center"}>
          <p className={"text-xs mb-8"}>
            A well-behaved user shares her data to an app, expecting no
            betrayal. But in the digital realm, sinister specters loom: data
            breaches, personal info misuse, and invasive prying. User’s privacy
            now hangs by a thread.
          </p>
          <p className={"text-xs"}>
            App developers are not at fault. Personalizing experiences in Web2
            necessitates data accumulation. Even when data is used responsibly,
            preventing misuse and security threats becomes challenging, inviting
            hackers and regulatory scrutiny.
          </p>
        </div>
        <div className={"w-1/2 mt-1"}>
          <img src={AppHose} alt="An application just fans out all the data" />
        </div>
      </div>
    );
  }

  function renderSection() {
    switch (section) {
      case "new":
        return renderNewSection();
      case "old":
        return renderOldSection();
      default:
        throw new UnreachableCaseError(section);
    }
  }

  function sectionClassNames(chosen: typeof section, original: string) {
    if (chosen === section) {
      return `${original} border-b-2`;
    } else {
      return `${original} border-dashed opacity-40`;
    }
  }

  return (
    <div className={"row pt-10 px-2 lg:px-0"}>
      <h2 className={"text-2xl"}>
        <span
          className={sectionClassNames(
            "old",
            "border-b-sky-600 border-b cursor-pointer",
          )}
          onClick={() => setSection("old")}
        >
          A Spoiled Tradition
        </span>{" "}
        <span className={"opacity-40"}>or</span>{" "}
        <span
          className={sectionClassNames(
            "new",
            "border-b-sky-600 border-b cursor-pointer",
          )}
          onClick={() => setSection("new")}
        >
          The Way Forward
        </span>
      </h2>
      {renderSection()}
    </div>
  );
}

function HelloForm() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [state, setState] = useState<"empty" | "loading" | "success">("empty");

  const handleSubmit = (e: TargetedEvent) => {
    e.preventDefault();
    setState("loading");
    fetch(
      "https://europe-west4-vc-provider.cloudfunctions.net/zcred-id-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      },
    )
      .then(() => {
        setState("success");
      })
      .catch((error) => {
        console.error(error);
        setState("success");
      });
  };

  const renderButton = () => {
    switch (state) {
      case "empty": {
        return (
          <button
            type={"submit"}
            className={
              "rounded-lg bg-sky-500 hover:bg-sky-800 text-white px-4 py-2"
            }
          >
            Join Waitlist
          </button>
        );
      }
      case "loading": {
        return <p>Sending...</p>;
      }
      case "success":
        return <p>Sent! We'll get back to you shortly!</p>;
      default:
        throw new UnreachableCaseError(state);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={"flex gap-2 pt-4"}>
        <input
          type={"text"}
          placeholder={"Name"}
          className={"w-1/2 p-2 border border-zinc-300 rounded-lg"}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <input
          type="email"
          className="w-1/2 p-2 border border-zinc-300 rounded-lg"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </div>
      <textarea
        placeholder={"Why I am excited to use zCred"}
        className={"w-full p-2 border border-zinc-300 rounded-lg mt-2 h-40"}
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
      ></textarea>
      <div className={"text-center"}>{renderButton()}</div>
    </form>
  );
}

export function IndexPage() {
  return (
    <Fragment>
      <div className={"row pt-12 px-2 lg:px-0"}>
        <h1 className="text-5xl font-bold">
          <a href={getPagePath($router, "home")} className={"logo"}>
            zCred
          </a>
        </h1>
      </div>
      <div className={"row px-2 lg:px-0 font-light"}>
        Zero Disclosure Verifiable Credentials
      </div>
      <div className={"row pt-8 px-2 lg:px-0"}>
        <p>
          Storing user data is a balancing act — compliance, individualized
          service, customer discretion. Historically, it meant a choice: a
          bespoke user journey or a clean slate of anonymity. With zCred, that
          choice is obsolete. We blend personalization and privacy, so you can
          build a delightful user experience while upholding trust.
        </p>
      </div>
      <TwoWays />
      <div className={"row pt-8 px-2 lg:px-0"}>
        <div className={styles.scenarioCardContainer}>
          <div className={styles.scenarioCard}>
            KYC
            <br />
            Compliance
            <div className={styles.imgOverlay}>
              <img src={PassportSvg} className={styles.scenarioKycImage} />
            </div>
          </div>
          <div className={styles.scenarioCard}>
            Sybil
            <br />
            Protection
            <div className={styles.imgOverlay}>
              <img src={SybilSvg} className={styles.scenarioKycImage} />
            </div>
          </div>
          <div className={styles.scenarioCard}>
            Cross-chain
            <br />
            credentials
            <div className={styles.imgOverlay}>
              <img src={IdCardClip} className={styles.scenarioKycImage} />
            </div>
          </div>
        </div>
        <div className={"row pt-10 px-2 lg:px-0"}>
          <p>
            We’re about to start beta testing with a small cohort of application
            developers. We’d especially love to bring on folks who have a
            speciﬁc vision for how zCred will ﬁt into their application
            lifecycle.
          </p>
          <HelloForm />
        </div>
        <div className={"row pt-10 px-2 lg:px-0"}>
          <p className={"text-center"}>
            While we are busy developing the system, feel free to try our tech
            demos.
          </p>
          <div className={styles.demoBox}>
            <div>
              <a
                className={styles.demoLink}
                href={"https://demo-mina.sybil.center"}
                target="_blank"
              >
                Demo on Mina
              </a>
              <p className={styles.demoBoxExpected}>
                Expects{" "}
                <a
                  className={"dark"}
                  href={"https://www.aurowallet.com"}
                  target="_blank"
                >
                  Auro Wallet
                </a>{" "}
                installed
              </p>
            </div>
            <div>
              <a
                className={styles.demoLink}
                href={"https://demo-noir.sybil.center"}
                target="_blank"
              >
                Demo on Ethereum
              </a>
              <p className={styles.demoBoxExpected}>
                Uses Noir. Expects{" "}
                <a
                  className={"dark"}
                  href={"https://metamask.io"}
                  target="_blank"
                >
                  MetaMask
                </a>{" "}
                installed
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={"grow"}>
            <ul className={styles.horizontal}>
              <li>
                <a href={"https://twitter.com/zcred_id"}>X (fka Twitter)</a>
              </li>
              <li>
                <a href={"https://discord.gg/kvFpVFEq4G"}>Discord</a>
              </li>
            </ul>
          </div>
          <div className={"grow-0"}>
            <ul className={styles.horizontal}>
              <li>
                <a href={getPagePath($router, "terms")}>
                  Terms & Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
