import { h } from "preact";
import { useStore } from "@nanostores/preact";
import { IndexPage } from "./index-page.js";
import { $router } from "./router.js";
import { TermsPage } from "./terms-page.js";

export function App() {
  const page = useStore($router);

  if (!page) {
    return <IndexPage />;
  }

  switch (page.route) {
    case "home":
      return <IndexPage />;
    case "terms":
      return <TermsPage />;
    default:
      return <IndexPage />;
  }
}
