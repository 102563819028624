.scenarioCard {
  @apply w-1/3 text-right bg-zinc-100 p-4 rounded-lg text-2xl overflow-hidden;
  height: 22vh;
}

.scenarioCardContainer {
  @apply flex flex-row gap-2;
}

.scenarioKycImage {
  position: relative;
  z-index: -1;
}

.imgOverlay {
  transform: rotate(15deg);
  @apply w-4/5 opacity-20 z-0;
  margin-top: -1%;
  margin-left: 7%;
  display: block;
}

.demoBox {
  @apply flex bg-zinc-100 p-8 pb-4 rounded-lg text-2xl overflow-hidden;
  background: linear-gradient(to bottom right, #5c62ec, #0ea5e9);
}

.demoBox > div {
  @apply text-center w-1/2;
}

.demoLink {
  @apply rounded-l-full rounded-r-full bg-white px-4 py-2 shadow-lg text-zinc-700 no-underline;
}

.demoLink:hover {
  @apply shadow-xl text-sky-800;
}

.demoBoxExpected {
  @apply text-xs mt-4;
}

.footer {
  @apply mt-10 p-4 px-2 border-t border-t-zinc-200 bg-zinc-100 flex-row flex text-sm;
}

.footer .horizontal {
  @apply list-none;
}

.footer .horizontal li {
  @apply inline px-2;
}
